import { useState, useRef, useEffect } from 'react';
import { usePersistedState } from '../../utils/usePersistedState';

let shouldResetTimer = JSON.parse(localStorage.getItem('shouldResetTimer') || true);
const useTimer = () => {
  // INFO It didn't seem to be getting populated fast enough to be used below... any way to fix this?
  const [_, setShouldResetTimer] = usePersistedState('shouldResetTimer', true);
  const [startTime, setStartTime] = usePersistedState('timerStartTime', null);
  const [pauseDurations, setPauseDurations] = usePersistedState('timerPauseDurations', []); // { start: <millis>, end: <millis> }
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const countRef = useRef(null);

  const secondsPassed = () => {
    let pauses = 0;
    for (const pause of pauseDurations) {
      if (pause.start && pause.end) {
        pauses += Math.abs(pause.end - pause.start);
      }
    }
    return (Math.abs(Date.now() - startTime - pauses) / 1000).toFixed(0);
  };

  useEffect(() => {
    if (isActive) {
      countRef.current = setInterval(() => {
        setTimer(secondsPassed());
      }, 500);
    }
  }, [isActive]);

  const handleStart = () => {
    setIsPaused(true);
    if (shouldResetTimer === true) {
      setStartTime(Date.now());
    }
    setShouldResetTimer(false);
    shouldResetTimer = false;
    setIsActive(true);
  };

  const handlePause = () => {
    clearInterval(countRef.current);
    setIsPaused(false);
    const pause = { start: Date.now() };
    pauseDurations.push(pause);
  };

  const handleResume = () => {
    setIsPaused(true);
    const durations = [...pauseDurations];
    const lastIndex = durations.length - 1;
    const pause = durations[lastIndex];
    pause.end = Date.now();
    durations[lastIndex] = pause;
    setPauseDurations(durations);
    countRef.current = setInterval(() => {
      setTimer(secondsPassed());
    }, 500);
  };

  const handleReset = () => {
    setTimer(0);
    localStorage.setItem('shouldResetTimer', JSON.stringify(true));
    shouldResetTimer = true;
    setShouldResetTimer(true);
    setPauseDurations([]);
    setIsStopped(false);
    handleStart();
  };

  const handleStop = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(false);
    setIsStopped(true);
    // TODO Include a stop timestamp? Right now, resuming after stopping it counts the time stopped as part of the duration
  };

  return { timer, isActive, isPaused, isStopped, handleStart, handlePause, handleResume, handleReset, handleStop };
};

export default useTimer;
