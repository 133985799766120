import { Chip, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { isIOS, isMobile } from 'mobile-device-detect';
import ReactSelect from 'react-select';
import { SelectStyle } from '../search-select';
import { Button } from 'reactstrap';
import { language, messages } from '../../utils/localeUtils';
import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ActionSheet from '../ActionSheet';
import { ReactComponent as CubaIcon } from '../../../common-assets/images/flags/cuba.svg';
import { ReactComponent as DominicanIcon } from '../../../common-assets/images/flags/dominican-republic.svg';
import { ReactComponent as NicaraguanIcon } from '../../../common-assets/images/flags/nicaragua.svg';
import { ReactComponent as HonduranIcon } from '../../../common-assets/images/flags/honduras.svg';
import { ReactComponent as EcuadorianIcon } from '../../../common-assets/images/flags/ecuador.svg';
import { ReactComponent as CostaRicanIcon } from '../../../common-assets/images/flags/costa-rica.svg';
import { ReactComponent as AmericanIcon } from '../../../common-assets/images/flags/united-states-of-america.svg';
import { ReactComponent as MexicanIcon } from '../../../common-assets/images/flags/mexico.svg';

const countries = [
  { name: 'Cuba', code: 'cu', flag: <CubaIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Dominican Republic', code: 'do', flag: <DominicanIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Nicaragua', code: 'ni', flag: <NicaraguanIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Honduras', code: 'hn', flag: <HonduranIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Ecuador', code: 'ec', flag: <EcuadorianIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Costa Rica', code: 'cr', flag: <CostaRicanIcon style={{ height: 22, width: 22 }} /> },
  { name: 'United States', code: 'us', flag: <AmericanIcon style={{ height: 22, width: 22 }} /> },
  { name: 'Mexico', code: 'us', flag: <MexicanIcon style={{ height: 22, width: 22 }} /> },
];

const priceRanges = [{
  value: 'all',
  label: 'All Prices',
  priceMin: null,
  priceMax: null,
}, {
  value: 'under-25',
  label: 'Under $25',
  priceMin: 0,
  priceMax: 24.99,
}, {
  value: '25-50',
  label: '$25 to $50',
  priceMin: 25,
  priceMax: 49.99,
}, {
  value: '50-100',
  label: '$50 to $100',
  priceMin: 25,
  priceMax: 49.99,
}, {
  value: '100-200',
  label: '$100 to $200',
  priceMin: 100,
  priceMax: 199.99,
}, {
  value: '200-plus',
  label: '$200 & Up',
  priceMin: 200,
  priceMax: null,
}];

const wrapperTypes = [
  'Brazilian Mata Fina',
  'Candela',
  'Connecticut',
  'Connecticut Shade',
  'Connecticut Broadleaf',
  'Corojo',
  'Criollo',
  'Cameroon',
  'Ecuador Habano',
  'Habano',
  'Maduro',
];

const shapes = [
  'All',
  'Belicoso',
  'Churchill',
  'Cigarillos',
  'Corona',
  'Corona Extra',
  'Corona Gorda',
  'Double Corona',
  'Double Perfecto',
  'Double Robusto',
  'Double Toro',
  'Figurado',
  'Gigante',
  'Gordo',
  'Gordo Extra',
  'Lancero',
  'Lancero/Panatela',
  'Lonsdale',
  'Perfecto',
  'Petite Corona',
  'Presidente',
  'Pyramid',
  'Robusto',
  'Robusto Extra',
  'Rothschild',
  'Salomon',
  'Short Robusto',
  'Toro',
  'Torpedo',
  'Wedge',
];

const types = [
  'Samplers',
  'Bundles',
  'Boxes',
  '5 Packs',
  'Singles',
  'Jars',
  'Tins',
];

const strengths = [
  { value: 1, label: 'Mellow' },
  { value: 2, label: 'Mellow-Medium' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'Medium-Full' },
  { value: 5, label: 'Full' },
];

const lengths = [
  'Under 4”',
  '4”-5"',
  '5”-6"',
  '6”-7"',
  'Over 7”',
];

const gauges = [
  'Under 43',
  '44-49',
  '50-55',
  '56-59',
  '60+',
];

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const ShopFilter = (props) => {
  const [state, setState] = React.useState(props.defaultState);

  const resetFilters = () => {
    props.onResetFilters && props.onResetFilters();
    props.onSelectedFilter && props.onSelectedFilter(false);
    props.onShowFilterDrawer && props.onShowFilterDrawer(false);
    setState(props.defaultState);
    setTimeout(() => {
      props.onFilterProducts && props.onFilterProducts(1, props.defaultState);
    }, 200);
  };

  const handleChipChange = (type, value) => () => {
    const typeSelection = `selected${type[0].toUpperCase() + type.slice(1)}`;
    const options = { ...state.selectedFilterOptions };

    if (type === 'length') {
      let min = 0;
      let max = 20;
      if (value.indexOf('Under') !== -1) {
        max = parseInt(value.replace('Under', '').replace(/"/g, '').trim());
      } else if (value.indexOf('Over') !== -1) {
        min = parseInt(value.replace('Over', '').replace(/"/g, '').trim());
      } else {
        min = parseInt(value.replace(/"/g, '').trim());
        max = min + 1;
      }
      options.length_min = min;
      options.length_max = max;
    } else if (type === 'gauge') {
      let min = 0;
      let max = 200;
      if (value.indexOf('Under') !== -1) {
        max = parseInt(value.replace('Under', '').replace(/"/g, '').trim());
      } else if (value.indexOf('+') !== -1) {
        min = parseInt(value.replace('+', '').replace(/"/g, '').trim());
      } else {
        const parts = value.split('-');
        min = parseInt(parts[0].trim());
        max = parseInt(parts[1].trim());
      }
      options.gauge_min = min;
      options.gauge_max = max;
    } else if (type === 'strength') {
      options.strength = value.value;
    } else {
      options[type] = value;
    }

    setState({
      ...state,
      selectedFilterOptions: options,
      [typeSelection]: value,
    });
  };

  const handlePriceChange = (value) => {
    console.log('Price range: ', value);
    const options = { ...state.selectedFilterOptions };
    options.price_min = value.priceMin;
    options.price_max = value.priceMax;
    setState({
      ...state,
      selectedFilterOptions: options,
      selectedPriceRange: value,
    });
  };

  const renderTypeChips = () => (
    <div style={styles.wrapper}>
      {types.map((type) => (
        <Chip
          color={state.selectedType === type ? 'primary' : 'default'}
          labelColor={state.selectedType === type ? '#ffffff' : ''}
          onClick={handleChipChange('type', type)}
          style={styles.chip}
          label={type}
        />
      ))}
    </div>
  );

  const renderCountryChips = () => (
    <div style={styles.wrapper}>
      {countries.map((country) => (
        <Chip
          color={state.selectedOrigin === country.name ? 'primary' : 'default'}
          labelColor={state.selectedOrigin === country.name ? '#ffffff' : 'inherit'}
          onClick={handleChipChange('origin', country.name)}
          style={styles.chip}
          icon={country.flag}
          label={<span style={{ marginLeft: 8 }}>{country.name}</span>}
        />
      ))}
    </div>
  );

  const renderPriceChips = () => (
    <div style={styles.wrapper}>
      {priceRanges.map((price) => (
        <Chip
          color={state.selectedPriceRange.value === price.value ? 'primary' : 'default'}
          labelColor={state.selectedPriceRange.value === price.value ? '#ffffff' : ''}
          onClick={() => handlePriceChange(price)}
          style={styles.chip}
          label={price.label}
        />
      ))}
    </div>
  );

  const renderWrapperChips = () => (
    <div style={styles.wrapper}>
      {wrapperTypes.map((wrapper) => (
        <Chip
          color={state.selectedWrapper === wrapper ? 'primary' : 'default'}
          labelColor={state.selectedWrapper === wrapper ? '#ffffff' : ''}
          onClick={handleChipChange('wrapper', wrapper)}
          style={styles.chip}
          label={wrapper}
        />
      ))}
    </div>
  );

  const renderStrengthChips = () => (
    <div style={styles.wrapper}>
      {strengths.map((strength) => (
        <Chip
          color={state.selectedStrength && state.selectedStrength.value === strength.value ? 'primary' : 'default'}
          labelColor={state.selectedStrength && state.selectedStrength.value === strength.value ? '#ffffff' : ''}
          onClick={handleChipChange('strength', strength)}
          style={styles.chip}
          label={strength.label}
        />
      ))}
    </div>
  );

  const renderLengthChips = () => (
    <div style={styles.wrapper}>
      {lengths.map((length) => (
        <Chip
          color={state.selectedLength === length ? 'primary' : 'default'}
          labelColor={state.selectedLength === length ? '#ffffff' : ''}
          onClick={handleChipChange('length', length)}
          style={styles.chip}
          label={length}
        />
      ))}
    </div>
  );

  const renderGaugeChips = () => (
    <div style={styles.wrapper}>
      {gauges.map((gauge) => (
        <Chip
          color={state.selectedGauge === gauge ? 'primary' : 'default'}
          labelColor={state.selectedGauge === gauge ? '#ffffff' : ''}
          onClick={handleChipChange('gauge', gauge)}
          style={styles.chip}
          label={gauge}
        />
      ))}
    </div>
  );

  // const renderRatingRadio = (value) => (
  //   <div>
  //     <Rating
  //       initialRating={value}
  //       emptySymbol="far fa-star"
  //       fullSymbol="fas fa-star"
  //       fractions={2}
  //       readonly
  //       style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginRight: 10 }}
  //     />
  //     {'& Up'}
  //   </div>
  // );

  const renderFilterOptions = () => (
    <div>
      {!isMobile && renderSort()}
      <h4 style={{ marginTop: 35 }}>Package Type</h4>
      {renderTypeChips()}
      {!props.hideCigarOptions && (
        <>
          <h4 style={{ marginTop: 35 }}>Country of Origin</h4>
          {renderCountryChips()}
        </>
      )}
      {props.showRatingFilter && (
        <>
          {/*<h4 style={{ marginTop: 35 }}>User Rating</h4>*/}
          {/*<RadioGroup*/}
          {/*  name="userRating"*/}
          {/*  value={selections.selectedRating}*/}
          {/*  onChange={(event, value) => {*/}
          {/*    setSelections({ ...selections, selectedRating: parseFloat(value) });*/}
          {/*    console.log(event);*/}
          {/*    const options = { ...selections.selectedFilterOptions };*/}
          {/*    options.rating = parseFloat(value);*/}
          {/*    setSelectedFilterOptions(options);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <FormControlLabel*/}
          {/*    value={4.5}*/}
          {/*    control={<Radio />}*/}
          {/*    label={renderRatingRadio(4.5)}*/}
          {/*    labelPlacement="end"*/}
          {/*  />*/}
          {/*  <FormControlLabel*/}
          {/*    value={4}*/}
          {/*    control={<Radio />}*/}
          {/*    label={renderRatingRadio(4)}*/}
          {/*    labelPlacement="end"*/}
          {/*  />*/}
          {/*  <FormControlLabel*/}
          {/*    value={3.5}*/}
          {/*    control={<Radio />}*/}
          {/*    label={renderRatingRadio(3.5)}*/}
          {/*    labelPlacement="end"*/}
          {/*  />*/}
          {/*  <FormControlLabel*/}
          {/*    value={3}*/}
          {/*    control={<Radio />}*/}
          {/*    label={renderRatingRadio(3)}*/}
          {/*    labelPlacement="end"*/}
          {/*  />*/}
          {/*  <FormControlLabel*/}
          {/*    value={0}*/}
          {/*    control={<Radio />}*/}
          {/*    label="Any Rating"*/}
          {/*    labelPlacement="end"*/}
          {/*  />*/}
          {/*</RadioGroup>*/}
        </>
      )}
      <h4 style={{ marginTop: 35 }}>Price</h4>
      {renderPriceChips()}
      {!props.hideCigarOptions && (
        <>
          <h4 style={{ marginTop: 35 }}>Wrapper Type</h4>
          {renderWrapperChips()}
        </>
      )}
      {!props.hideCigarOptions && (
        <>
          <h4 style={{ marginTop: 35 }}>Strength</h4>
          {renderStrengthChips()}
        </>
      )}
      <h4 style={{ marginTop: 35 }}>Shape</h4>
      <div style={{ marginBottom: 12 }}>
        {isMobile && (
          <select
            className="form-control"
            onChange={(e) => props.onSelectedShape && props.onSelectedShape(e.target.value)}
          >
            {shapes.map((shape) => <option>{shape}</option>)}
          </select>
        )}
        {!isMobile && (
          <ReactSelect
            styles={SelectStyle}
            className="form-select"
            defaultValue={{ value: shapes[0], label: shapes[0] }}
            isSearchable
            name="shapes"
            options={shapes.map((shape) => ({ value: shape, label: shape }))}
            onChange={(option) => props.onSelectedShape && props.onSelectedShape(option.value)}
          />
        )}
      </div>
      <h4 style={{ marginTop: 35 }}>Length</h4>
      {renderLengthChips()}
      <h4 style={{ marginTop: 35 }}>Ring Gauge</h4>
      {renderGaugeChips()}
      <div className="filter-action-buttons">
        <Button
          outline
          color="default"
          onClick={resetFilters}
          style={{ flex: 1, marginRight: 10 }}
        >
          {messages[language]?.reset || 'Reset'}
        </Button>
        <Button
          color="primary"
          style={{ flex: 1 }}
          onClick={() => {
            props.onChangeFilterOptions && props.onChangeFilterOptions(state.selectedFilterOptions);
            props.onShowFilterDrawer && props.onShowFilterDrawer(false);
            props.onSelectedFilter && props.onSelectedFilter(true);
            props.onFilterProducts && props.onFilterProducts(1, state.selectedFilterOptions);
          }}
        >
          {messages[language]?.apply || 'Apply'}
        </Button>
      </div>
    </div>
  );

  const renderFilterDrawer = () => (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={props.showFilterDrawer}
        // onClick={() => setState({ showFilterDrawer: false })}
        onKeyDown={() => props.onShowFilterDrawer && props.onShowFilterDrawer(false)}
        onClose={() => props.onShowFilterDrawer && props.onShowFilterDrawer(false)}
      >
        <div style={{ width: 260, marginLeft: 20, marginRight: 20 }}>
          { renderFilterOptions() }
        </div>
      </SwipeableDrawer>
    </div>
  );

  const renderSort = () => (
    <div>
      <h4 style={{ marginTop: 35 }}>Sort</h4>
      <div style={styles.wrapper}>
        {props.sortItems.map((item) => (
          <Chip
            color={props.selectedSort === item.value ? 'primary' : 'default'}
            labelColor={props.selectedSort === item.value ? '#ffffff' : ''}
            onClick={() => {
              console.log('Sort: ', item);
              const options = { ...props.filterOptions };
              options.sort = item.value;
              console.log(options);
              props.onChangeFilterOptions && props.onChangeFilterOptions(options);
              props.onChangeSort && props.onChangeSort(item.value);
            }}
            style={styles.chip}
            label={item.content}
          />
        ))}
      </div>
    </div>
  );

  const renderSortSheet = () => {
    if (isIOS) {
      return (
        <div>
          <ActionSheet
            show={props.showSortDrawer}
            menus={props.sortItems}
            onRequestClose={props.onToggleSortDrawer}
            cancelText="Cancel"
          />
        </div>
      );
    }
    return (
      <div>
        <Drawer
          anchor="bottom"
          open={props.showSortDrawer}
          onClick={props.onToggleSortDrawer}
          onRequestClose={props.onToggleSortDrawer}
        >
          <div
            role="presentation"
            onClick={props.onToggleSortDrawer}
            onKeyDown={props.onToggleSortDrawer}
          >
            <List>
              {/* FIXME Render a cancel button? */}
              { props.sortItems.map((item) => (
                <ListItem onClick={item.onClick}>
                  <ListItemText primary={item.content} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  };

  return (
    <>
      {isMobile && renderSortSheet()}
      {isMobile && renderFilterDrawer()}
      {!isMobile && renderFilterOptions()}
    </>
  );
};

export default ShopFilter;
