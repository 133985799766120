import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isIOS, isMobile, isMobileOnly } from 'mobile-device-detect';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Rating from 'react-rating';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import Hashids from 'hashids/cjs';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import Chip from '@material-ui/core/Chip';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import VenueEditor from '../../pages/VenueEditor';
import { renderTimestamp } from '../../utils/formatting';
import CigarEditor from '../../pages/CigarEditor';
import LoadingIndicator from '../LoadingIndicator';
import { resetSearchAction as actionResetSearchAction } from '../../actions';
import { config } from '../../settings';
import { SearchStore, SettingsStore } from '../../stores';
import Product from '../../models/Product';
import ModalDialog from '../ModalDialog';
import { saveItem } from '../../pages/SavedItems/helper';
import SearchTabs, { resultTypes } from './components/search-tabs';
import GroupListingCard from '../GroupListingCard';
import Badge from '@material-ui/core/Badge';
import Venue from '../../models/Venue';
import ShareIntent from '../../utils/shareUtils';
import ShareItem from '../../models/ShareItem';
import axios from 'axios';
import { Resize } from '../../utils/imageUtils';
import Cigar from '../../models/Cigar';
import { redirectAuth } from '../../utils/redirect';
import BuyButton from '../BuyButton';

const hashids = new Hashids('', 12);

function SearchResults(props) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const [selectedResults, setSeletedResults] = useState('cigars');
  const [selectedResultsIndex, setSeletedResultsIndex] = useState(0);
  const [showCigarModal, setShowCigarModal] = useState(false);
  const [helpMatch, setHelpMatch] = useState(false);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [lastPathname, setLastPathname] = useState(null);
  // const [selectedCigar, setSeletedCigar] = useState('cigars');
  // const [selectedVenue, setSelectedVenue] = useState('cigars');

  const cigars = SearchStore.useState((s) => s.cigars);
  const venues = SearchStore.useState((s) => s.venues);
  const brands = SearchStore.useState((s) => s.brands);
  const groups = SearchStore.useState((s) => s.groups);
  const users = SearchStore.useState((s) => s.users);
  const products = SearchStore.useState((s) => s.products);
  const articles = SearchStore.useState((s) => s.articles);

  const cigarsLoading = SearchStore.useState((s) => s.cigarsLoading);
  const venuesLoading = SearchStore.useState((s) => s.venuesLoading);
  const brandsLoading = SearchStore.useState((s) => s.brandsLoading);
  const groupsLoading = SearchStore.useState((s) => s.groupsLoading);
  const usersLoading = SearchStore.useState((s) => s.usersLoading);
  const productsLoading = SearchStore.useState((s) => s.productsLoading);
  const articlesLoading = SearchStore.useState((s) => s.articlesLoading);

  const searchHolder = {
    cigars,
    venues,
    brands,
    groups,
    users,
    products,
    articles,
    cigarsLoading,
    venuesLoading,
    brandsLoading,
    groupsLoading,
    usersLoading,
    productsLoading,
    articlesLoading,
  };

  useEffect(() => {
    console.log('Search props:');
    console.log(props);

    if (props.action && props.action.search) {
      initDefaultResultsView(props.action.search.results);
      if (typeof props.onOpen === 'function') {
        props.onOpen();
      }
    } else if (props.location.pathname.indexOf('deals') !== -1 || props.location.pathname.indexOf('shop') !== -1) {
      initDefaultResultsView('products');
    } else if (props.location.pathname.indexOf('venues') !== -1) {
      initDefaultResultsView('venues');
    } else if (props.location.pathname.indexOf('groups') !== -1) {
      initDefaultResultsView('groups');
    } else if (props.location.pathname.indexOf('users') !== -1 || props.location.pathname.indexOf('profile') !== -1) {
      initDefaultResultsView('users');
    } else if (props.location.pathname.indexOf('articles') !== -1) {
      initDefaultResultsView('articles');
    } else if (props.location.pathname.indexOf('brands') !== -1) {
      initDefaultResultsView('brands');
    }

    if (lastPathname && (props.location.pathname !== lastPathname)) {
      console.log('Search results should hide...');
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    } else {
      setLastPathname(props.location.pathname);
    }

    return () => {
      // FIXME This causes the results to hide when tapping another quick action link - not sure we need this anymore anyway
      // if (typeof props.onClose === 'function') {
      //   props.onClose();
      // }
    };
  }, [props.action && props.action.search && props.action.search.results, props.location.pathname]);

  const initDefaultResultsView = (type) => {
    let typeIndex = 0;
    resultTypes.forEach((item, index) => {
      if (item.value === type) {
        typeIndex = index;
      }
    });
    setSeletedResults(type);
    setSeletedResultsIndex(typeIndex);
  };

  const getImage = (item) => {
    const placeholderCigar = darkMode ? PlaceholderDark.band : Placeholder.band;
    const placeholderAvatar = darkMode ? PlaceholderDark.avatar : Placeholder.avatar;
    // TODO Use a helper class like the model classes for this
    // console.log(item);
    if (selectedResults === 'cigars') {
      return Resize.size(Cigar.getBandImage(item), { height: 100, width: 100, cropType: 'fit' });
    }
    if (selectedResults === 'brands') {
      // return Resize.size(Brand.getImage(item), { height: 100, width: 100, cropType: 'fit' });
      return Resize.size(item.logo_image_url, { height: 100, width: 100, cropType: 'fit' });
    }
    if (selectedResults === 'products') {
      return Resize.size(Product.getImage(item), { height: 100, width: 100, cropType: 'crop' });
    }
    if (selectedResults === 'users') {
      if (item.image_url) {
        return Resize.size(item.image_url);
      }
      return placeholderAvatar.replace('/placeholder/', '/placeholder/100x100/');
    }
    if (selectedResults === 'venues') {
      return Resize.size(Venue.getImage(item), { height: 100, width: 100, cropType: 'crop' });
    }
    if (selectedResults === 'articles') {
      if (item.image) {
        return Resize.size(item.image, { height: 100, width: 100, cropType: 'crop' });
      }
      if (item.featured_image) {
        return Resize.size(item.featured_image.url, { height: 100, width: 100, cropType: 'crop' });
      }
    }
    return placeholderCigar.replace('/placeholder/', '/placeholder/100x100/');
  };

  const getVenueTitle = (venue) => {
    let title = venue.name;
    if (venue.location && venue.location.city && venue.location.state) {
      title += ` - ${venue.location.city}, ${venue.location.state}`;
    } else if (venue.website) {
      title += ` - ${venue.website}`;
    }
    return title;
  };

  const onClick = (item, params) => {
    const { auth } = props;
    console.log('Clicked item:');
    console.log(item);
    if (props.action && props.action.search) {
      console.log(props.action);
      if (!auth || !auth.user) {
        console.log(window.location.href);
        console.log(window.location.pathname);
        let endpoint = window.location.href.split('?')[0];
        if (window.location.pathname !== '/') {
          endpoint = endpoint.replace(window.location.pathname, '');
        } else {
          endpoint = endpoint.substring(0, endpoint.length - 1);
        }
        const redirectUrl = `${endpoint}/${props.action.search.route.replace(':uid', hashids.encode(item.id))}${props.action.search.params}&itemId=${item.id}`;
        redirectAuth(redirectUrl, false);
      } else {
        console.log('Item for string:');
        console.log(props.action.search.params.substring(0, props.action.search.params.length - 1));
        if (props.action.search.replace) {
          props.history.replace({
            pathname: `/${props.action.search.route.replace(':uid', hashids.encode(item.id))}`,
            search: `${props.action.search.params}&itemId=${item.id}${params || ''}`,
            state: { [props.action.search.params.substring(0, props.action.search.params.length - 1)]: item },
          });
        } else {
          props.history.push({
            pathname: `/${props.action.search.route.replace(':uid', hashids.encode(item.id))}`,
            search: `${props.action.search.params}&itemId=${item.id}${params || ''}`,
            state: { [props.action.search.params.substring(0, props.action.search.params.length - 1)]: item },
          });
        }
      }
      props.resetSearchAction();
    } else {
      console.log('Item for string:');
      console.log(selectedResults.substring(0, selectedResults.length - 1));
      props.history.push({
        pathname: `/${selectedResults}/${hashids.encode(item.id)}`,
        search: params,
        state: { [selectedResults.substring(0, selectedResults.length - 1)]: item },
      });
    }
    if (typeof props.onSelect === 'function') {
      props.onSelect(item);
    }
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
    if (window.analytics) {
      // TODO Include the search_term (query) too? As well as the user that ran the search?
      window.analytics.track('Selected Search Results', { item_id: item.id, item_type: selectedResults });
    }
  };

  // FIXME Get this from the Product model
  const productTitle = (product) => {
    if (product.packaging && product.contents && product.contents.length === 1 && product.contents[0].vitola) {
      return `${product.name} ${product.contents[0].vitola.formatted_name} - ${product.packaging.type} of ${product.packaging.quantity}`;
    }
    if (product.packaging) {
      let qty = parseInt(product.packaging.quantity);
      if (product.packaging.type === 'Sampler') {
        for (let i = 0; i < product.contents.length; i++) {
          qty += product.contents[i].quantity;
        }
      }
      if (qty > 0) {
        return `${product.name} - ${product.packaging.type} of ${qty}`;
      }
      return `${product.name} - ${product.packaging.type}`;
    }
    return product.name;
  };

  const renderCigarRow = (item) => (
    <div style={{ borderBottom: `1px solid ${darkMode ? '#2b2b2b' : '#dfdfdf'}` }}>
      <ListItem key={`cigar-item-${item.id}`} onClick={() => onClick(item)}>
        <Avatar src={getImage(item)} className="avatar-contained" style={{ marginRight: 10 }} alt={item.full_name}>
          <img src={Placeholder.cigar} style={{ height: 40, width: 40 }} />
        </Avatar>
        <ListItemText
          primary={(
            <span className="padded-multiline" style={{ marginLeft: 10 }}>{item.full_name}</span>
          )}
          secondary={(
            <div>
              <Rating
                initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
              />
              <span style={{ marginLeft: 10 }}>
                {`${item.total_ratings || 0} ratings`}
              </span>
            </div>
          )}
        />
        <ListItemSecondaryAction>
          <span style={{ fontSize: 22 }}>{item.avg_rating ? parseInt(item.avg_rating) : ''}</span>
        </ListItemSecondaryAction>
      </ListItem>
      {item.vitolas && item.vitolas.length > 0 && (
        <div style={{ overflowX: 'scroll', padding: 2, display: 'flex' }}>
          {item.vitolas.map((vitola) => (
            <Chip
              key={vitola.id}
              label={vitola.formatted_name}
              style={{ margin: 4 }}
              onClick={() => {
                onClick(item, `&vitola=${vitola.id}`);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );

  const renderProductRow = (item) => (
    <ListItem key={`product-item-${item.id}`} onClick={() => onClick(item)}>
      <Avatar src={getImage(item)} style={{ marginRight: 10 }} alt={productTitle(item)}>
        <img src={Placeholder.cigar} style={{ height: 40, width: 40 }} />
      </Avatar>
      <ListItemText
        style={{ paddingRight: 36 }}
        primary={(
          <span className="padded-multiline" style={{ marginLeft: 10 }}>{productTitle(item)}</span>
        )}
        secondary={(
          <div>
            <Rating
              initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
              emptySymbol="far fa-star"
              fullSymbol="fas fa-star"
              fractions={2}
              readonly
              style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
            />
            <span style={{ marginLeft: 10 }}>
              {`${item.total_ratings || 0} ratings`}
            </span>
          </div>
        )}
      />
      <ListItemSecondaryAction>
        <BuyButton
          product={new Product(item)}
          showPrefix={false}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );

  const renderGroupRow = (group) => <GroupListingCard group={group} compact />;

  const renderUserRow = (item) => (
    <ListItem key={`user-item-${item.id}`} onClick={() => onClick(item)}>
      <Avatar src={item.image_url} style={{ marginRight: 10 }} alt={item.full_name}>
        <img src={Placeholder.avatar} style={{ height: 40, width: 40 }} />
      </Avatar>
      {/* TODO Button to add friend from here? */}
      <ListItemText
        primary={(
          <span className="padded-multiline" style={{ marginLeft: 10 }}>{item.full_name}</span>
        )}
        // secondary={(
        //   <div>
        //     TODO Whether they are already friends or something
        //   </div>
        // )}
      />
    </ListItem>
  );

  const renderVenueRow = (item) => (
    <ListItem key={`venue-item-${item.id}`} onClick={() => onClick(item)}>
      <Avatar src={getImage(item)} style={{ marginRight: 10 }} alt={item.name}>
        <img src={Placeholder.venue} style={{ height: 40, width: 40 }} />
      </Avatar>
      <ListItemText
        primary={(
          <span className="padded-multiline" style={{ marginLeft: 10 }}>{getVenueTitle(item)}</span>
        )}
        secondary={(
          <div>
            <Rating
              initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
              emptySymbol="far fa-star"
              fullSymbol="fas fa-star"
              fractions={2}
              readonly
              style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
            />
            <span style={{ marginLeft: 10 }}>
              {`${item.total_ratings || 0} ratings`}
            </span>
          </div>
        )}
      />
    </ListItem>
  );

  const renderBrandRow = (item) => (
    <ListItem key={`brand-item-${item.id}`} onClick={() => onClick(item)}>
      <Avatar src={item.logo_image_url} style={{ marginRight: 10 }} alt={item.name}>
        <img src={Placeholder.cigar} style={{ height: 40, width: 40 }} />
      </Avatar>
      <ListItemText
        primary={(
          <span className="padded-multiline" style={{ marginLeft: 10 }}>{item.name}</span>
        )}
        secondary={(
          <div>
            <Rating
              initialRating={item.avg_rating ? item.avg_rating / 20 : 0}
              emptySymbol="far fa-star"
              fullSymbol="fas fa-star"
              fractions={2}
              readonly
              style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
            />
            <span style={{ marginLeft: 10 }}>
              {`${item.total_ratings || 0} ratings`}
            </span>
          </div>
        )}
      />
    </ListItem>
  );

  const renderLoading = () => {
    if (searchHolder[`${selectedResults}Loading`]) {
      return (
        <div style={{ textAlign: 'center' }}>
          <LoadingIndicator />
        </div>
      );
    }
    return null;
  };

  const renderNoResults = () => {
    if (!searchHolder[selectedResults]) {
      if (selectedResults === 'cigars') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (props.auth && props.auth.user) {
                  setHelpMatch(true);
                  setShowCigarModal(true);
                } else {
                  redirectAuth(); // TODO Include action in query params to show the cigar modal?
                }
              }}
            >
              <span style={{ marginLeft: 8 }}>
                {'Not sure what your cigar is?'}
                {isMobile ? <button style={{ marginLeft: 10 }} className="btn btn-outline-grey">Add Image</button> : <span style={{ color: '#d6c290', marginLeft: 10 }}>Click here to match</span>}
              </span>
              {/* TODO When we allow band and barcode scanning, add these in */}
              {/*<div>*/}
              {/*  <button style={{ marginLeft: 10 }} className="btn btn-outline-grey"><Icon name={['fas', 'barcode']} vendor="fa" style={{ marginRight: 8 }} />Scan Barcode</button>*/}
              {/*  <button style={{ marginLeft: 10 }} className="btn btn-outline-grey"><Icon name="image" style={{ marginRight: 8 }} />Search By Band Image</button>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      }
      if (selectedResults === 'products') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search products by name, SKU, or barcode</div>
          </div>
        );
      }
      if (selectedResults === 'users') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search users by name or email address</div>
          </div>
        );
      }
      if (selectedResults === 'venues') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search shops, lounges, or online retailers</div>
          </div>
        );
      }
      if (selectedResults === 'brands') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search cigar brands, podcasts, blogs, etc</div>
          </div>
        );
      }
      if (selectedResults === 'groups') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search groups</div>
          </div>
        );
      }
      if (selectedResults === 'articles') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>Search for Cigar 101 articles, expert reviews, and more</div>
          </div>
        );
      }
    }
    {console.log('Selected results:')}
    {console.log(searchHolder[selectedResults])}
    if (searchHolder[selectedResults] && !searchHolder[selectedResults].length) {
      if (selectedResults === 'cigars') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No cigars found</div>
          </div>
        );
      }
      if (selectedResults === 'products') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No products found</div>
          </div>
        );
      }
      if (selectedResults === 'users') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No users found</div>
            {props.auth && props.auth.user && props.auth.user.id && (
              <Button
                style={{ marginTop: 12 }}
                color="primary"
                onClick={() => {
                  const hasWebContactAccess = ('contacts' in navigator && 'ContactsManager' in window);
                  const canAccessContacts = window.Capacitor.isNative || hasWebContactAccess;
                  if (canAccessContacts) {
                    const contactProps = ['name', 'email', 'tel', 'icon'];
                    const opts = { multiple: true };
                    try {
                      navigator.contacts.select(contactProps, opts).then((contacts) => {
                        // TODO Would be nice to update the endpoint to also include phone numbers. Also, since we get names, should
                        //  we include those in the database? We can potentially skip the sign up process and only ask them to create
                        //  a password if we do store them alongside the email and phone # (but how to associate the phone # with
                        //  multiple emails or vice versa? - save them as arrays just like they're pulled?)
                        const emails = [];
                        contacts.filter((contact) => contact.email.length > 0).forEach((contact) => {
                          emails.concat(contact.email);
                        });
                        console.log(emails);
                        // FIXME This works, but the problem is in that it only allows the user to pick some contacts - not all
                        //  (unless they manually click them all one by one). The plus side of this is that the contacts are more
                        //  specific to be known cigar smokers or have an interest, so our database will be in better shape.
                        axios.post(`${config.apiEndPoint}/users/${props.auth.user.id}/import`, emails).then((res) => {
                          const invites = res.data;
                          // TODO Anything? Success alert/toast?
                          // setUserSuggestions(invites.filter((invite) => invite.type === 'type_none').map((invite) => invite.user));
                          // setUserInvitations(invites.filter((invite) => invite.type === 'type_new').map((invite) => invite.user));
                        });
                      }).catch((err) => {
                        // console.error(err);
                        alert(err);
                      });
                    } catch (err) {
                      // TODO Pass to Sentry?
                      console.error(err);
                      alert(err);
                    }
                  } else {
                    ShareIntent.share(new ShareItem({
                      title: 'Join Boxpressd',
                      text: 'Check out Boxpressd, a great app for rating and sharing notes about great cigars.',
                      image: null, // TODO - for now it will default to the meta image
                      url: 'https://bxpr.sd/join', // A Branch quick link that redirects to the registration page
                    }));
                  }
                }}
              >
                {'Invite friends'}
              </Button>
            )}
          </div>
        );
      }
      if (selectedResults === 'groups') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No groups found</div>
          </div>
        );
      }
      if (selectedResults === 'venues') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No venues found</div>
          </div>
        );
      }
      if (selectedResults === 'brands') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No brands found</div>
          </div>
        );
      }
      if (selectedResults === 'articles') {
        return (
          <div style={{ textAlign: 'center' }}>
            <img />
            <div>No articles found</div>
          </div>
        );
      }
    }
    return null;
  };

  const renderNotFoundRow = () => {
    if (selectedResults === 'cigars') {
      return (
        <ListItem
          key="cigar-item-not-found"
          onClick={() => {
            if (props.auth && props.auth.user) {
              setHelpMatch(false);
              setShowCigarModal(true);
              $(document).find('.search-results-container').removeClass('overlay');
              $(document).find('#mobile-search-bar').removeClass('overlay');
            } else {
              redirectAuth(); // TODO Include action in query params to show the cigar modal?
            }
          }}
        >
          <div style={{ width: '100%', textAlign: 'center', padding: 12, cursor: 'pointer' }}>
            <span>
              {"Can't find your cigar?"}
              {isMobile ? <button style={{ marginLeft: 10 }} className="btn btn-outline-grey">Add New Cigar</button> : <span style={{ color: '#d6c290', marginLeft: 8 }}>Click here to add</span>}
            </span>
          </div>
        </ListItem>
      );
    }
    if (selectedResults === 'venues') {
      return (
        <ListItem
          key="venue-item-not-found"
          onClick={() => {
            if (props.auth && props.auth.user) {
              setShowVenueModal(true);
            } else {
              redirectAuth(); // TODO Include action in query params to show the venue modal?
            }
          }}
        >
          <div style={{ width: '100%', textAlign: 'center', padding: 12, cursor: 'pointer' }}>
            <span>
              {"Can't find the venue?"}
              {isMobile ? <button style={{ marginLeft: 10 }} className="btn btn-outline-grey">Add New Venue</button> : <span style={{ color: '#d6c290', marginLeft: 8 }}>Click here to add</span>}
            </span>
          </div>
        </ListItem>
      );
    }
    return null;
  };

  const renderArticleRow = (item) => {
    let { author } = item;
    if (item.author && typeof item.author === 'object') {
      author = item.author.display_name;
    }
    const date = item.date || item.timestamp;
    return (
      <ListItem key={`article-item-${item.id}`} onClick={() => onClick(item)}>
        <Avatar src={getImage(item)} style={{ marginRight: 10 }} alt={item.title}>
          <img
            src={Placeholder.cigar}
            style={{
              height: 40,
              width: 40,
            }}
          />
        </Avatar>
        <ListItemText
          primary={(
            <span className="padded-multiline" style={{ marginLeft: 10 }} dangerouslySetInnerHTML={{ __html: item.title }} />
          )}
          secondary={(
            <div>
              <span style={{ marginLeft: 10 }}>
                {`By ${author} on ${renderTimestamp(date)}`}
              </span>
            </div>
          )}
        />
      </ListItem>
    );
  };

  const renderList = () => (
    <TouchScrollable>
      <List
        style={{
          flex: 1,
          overflowY: 'scroll',
          paddingBottom: isIOS ? 60 : 'inherit',
          height: isMobileOnly ? 'auto' : (props.height || 'calc(100vh - 128px)'),
        }}
      >
        <div style={{ maxWidth: 450, margin: 'auto' }}>
          {renderLoading()}
          {!searchHolder[`${selectedResults}Loading`] && renderNoResults()}
          {!searchHolder[`${selectedResults}Loading`] && searchHolder[selectedResults] && searchHolder[selectedResults].map((item) => {
            if (selectedResults === 'cigars') {
              return renderCigarRow(item);
            }
            if (selectedResults === 'products') {
              return renderProductRow(item);
            }
            if (selectedResults === 'users') {
              return renderUserRow(item);
            }
            if (selectedResults === 'groups') {
              return renderGroupRow(item);
            }
            if (selectedResults === 'venues') {
              return renderVenueRow(item);
            }
            if (selectedResults === 'brands') {
              return renderBrandRow(item);
            }
            if (selectedResults === 'articles') {
              return renderArticleRow(item);
            }
            return null;
          })}
          {searchHolder[selectedResults] && renderNotFoundRow(selectedResults)}
        </div>
      </List>
    </TouchScrollable>
  );

  if (isMobileOnly) {
    return (
      <div
        className="search-results-container"
        onTouchStart={() => document.activeElement.blur()}
      >
        {/*<ScrollLock isActive={isIOS} />*/}
        <SearchTabs
          {...props}
          style={{ display: (props.action && props.action.search) ? 'none' : 'inline-block' }}
          value={selectedResultsIndex}
          onClick={(type, index) => {
            setSeletedResults(type.value);
            setSeletedResultsIndex(index);
          }}
        />

        {renderList()}

        {showCigarModal && (
          <CigarEditor
            // cigar={selectedCigar}
            open={showCigarModal}
            helpMatch={helpMatch}
            toggle={() => setShowCigarModal(!showCigarModal)}
            onClose={() => setShowCigarModal(false)}
          />
        )}

        <VenueEditor
          // venue={selectedVenue}
          open={showVenueModal}
          toggle={() => setShowVenueModal(!showVenueModal)}
          onClose={() => setShowVenueModal(false)}
        />
      </div>
    );
  }
  return (
    <div
      className="search-results-container"
      onTouchStart={() => document.activeElement.blur()}
    >
      <div className="row">
        <div className={!props.hideTabs ? 'col-md-9' : 'col-md-12'}>
          {renderList()}
        </div>
        {!props.hideTabs && (
          <div className="col-md-3" style={{ paddingLeft: 0, paddingRight: 30 }}>
            <List className="transparent-background">
              {resultTypes.map((type, index) => (
                <ListItem
                  button
                  key={`search-tab-${type.value}`}
                  selected={selectedResults === type.value}
                  onClick={() => {
                    setSeletedResults(type.value);
                    setSeletedResultsIndex(index);
                  }}
                >
                  {/*<Avatar src={type.icon} style={{ marginRight: 10 }}>*/}
                  {/*  <img*/}
                  {/*    src={Placeholder.cigar}*/}
                  {/*    style={{*/}
                  {/*      height: 40,*/}
                  {/*      width: 40,*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</Avatar>*/}
                  <ListItemText primary={type.name} />
                  <ListItemSecondaryAction>
                    <Badge
                      style={{ marginRight: 10 }}
                      badgeContent={searchHolder[type.value] && searchHolder[type.value].length}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>

      {showCigarModal && (
        <CigarEditor
          // cigar={selectedCigar}
          open={showCigarModal}
          helpMatch={helpMatch}
          toggle={() => setShowCigarModal(!showCigarModal)}
          onClose={() => setShowCigarModal(false)}
        />
      )}

      <VenueEditor
        // venue={selectedVenue}
        open={showVenueModal}
        toggle={() => setShowVenueModal(!showVenueModal)}
        onClose={() => setShowVenueModal(false)}
      />
    </div>
  );
}

SearchResults.propTypes = {
  action: PropTypes.object,
  // results: PropTypes.array,
  history: PropTypes.object,
};

SearchResults.defaultProps = {
  action: null,
  // results: [],
  history: {},
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    action: state.get('actions').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetSearchAction: () => dispatch(actionResetSearchAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResults));
