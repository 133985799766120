import Tabs from '@material-ui/core/Tabs';
import { isMobile } from 'mobile-device-detect';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import React from 'react';
import { SearchStore } from '../../../stores';

export const resultTypes = [{
  value: 'cigars', // INFO These need to match the route
  name: 'Cigars',
}, {
  value: 'products',
  name: 'Products',
}, {
  value: 'users',
  name: 'Users',
}, {
  value: 'venues',
  name: 'Venues',
}, {
  value: 'brands',
  name: 'Brands',
}, {
  value: 'groups',
  name: 'Groups',
}, {
  value: 'articles',
  name: 'Articles',
}];

function SearchTabs(props) {
  const cigars = SearchStore.useState((s) => s.cigars);
  const venues = SearchStore.useState((s) => s.venues);
  const brands = SearchStore.useState((s) => s.brands);
  const groups = SearchStore.useState((s) => s.groups);
  const users = SearchStore.useState((s) => s.users);
  const products = SearchStore.useState((s) => s.products);
  const articles = SearchStore.useState((s) => s.articles);

  const cigarsLoading = SearchStore.useState((s) => s.cigarsLoading);
  const venuesLoading = SearchStore.useState((s) => s.venuesLoading);
  const brandsLoading = SearchStore.useState((s) => s.brandsLoading);
  const groupsLoading = SearchStore.useState((s) => s.groupsLoading);
  const usersLoading = SearchStore.useState((s) => s.usersLoading);
  const productsLoading = SearchStore.useState((s) => s.productsLoading);
  const articlesLoading = SearchStore.useState((s) => s.articlesLoading);

  const searchHolder = {
    cigars,
    venues,
    brands,
    groups,
    users,
    products,
    articles,
    cigarsLoading,
    venuesLoading,
    brandsLoading,
    groupsLoading,
    usersLoading,
    productsLoading,
    articlesLoading,
  };
  return (
    <Tabs
      style={props.style}
      value={props.value}
      variant="scrollable"
      scrollButtons={false}
      indicatorColor="primary"
      textColor="primary"
      aria-label="Search Results Filters"
      centered={!isMobile}
    >
      {resultTypes.map((item, index) => (
        <Tab
          label={(
            <Badge
              badgeContent={searchHolder[item.value] && searchHolder[item.value].length}
              color="primary"
            >
              {item.name}
            </Badge>
          )}
          onClick={() => props.onClick(item, index)}
        />
      ))}
    </Tabs>
  );
}

export default SearchTabs;
