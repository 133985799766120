import React, { useEffect } from 'react';
import useTimer from './useTimer';
import './style.scss';

const formatDuration = (timer, seconds = true) => {
  const getSeconds = `0${(timer % 60)}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
  if (seconds) {
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  }
  return `${getHours}:${getMinutes}`;
};

// May be a better approach here - https://dev.to/abdulbasit313/how-to-develop-a-stopwatch-in-react-js-with-custom-hook-561b
export default function StopWatch(props) {
  const {
    timer,
    isActive,
    isPaused,
    isStopped,
    handleStart,
    handlePause,
    handleResume,
    handleReset,
    handleStop,
    startTime,
    pauseDurations,
  } = useTimer();

  useEffect(() => {
    if (props.started) {
      handleStart();
    }
  }, [props.started]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(getDuration(), timer);
    }
  }, [timer]);

  useEffect(() => {
    if (isStopped && props.onStop) {
      props.onStop(getDuration());
    }
  }, [isStopped]);

  const getDuration = () => formatDuration(timer, false);

  return (
    <div className="stop-watch">
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>
          {isPaused && isActive && (
            <div
              className="stopwatch-btn"
              onClick={() => {
                if (typeof props.onPause === 'function') {
                  props.onPause(pauseDurations);
                }
                handlePause();
              }}
            >
              {'Pause'}
            </div>
          )}
          {isStopped && (
            <div className="stopwatch-btn" onClick={handleReset}>
              {'Reset'}
            </div>
          )}
          {!isActive && !isPaused && !isStopped && (
            <div
              className="stopwatch-btn"
              onClick={() => {
                if (typeof props.onStart === 'function') {
                  props.onStart(startTime);
                }
                handleStart();
              }}
            >
              {'Start'}
            </div>
          )}
          {isActive && !isPaused && !isStopped && (
            <div className="stopwatch-btn" onClick={handleResume}>
              {'Resume'}
            </div>
          )}
        </div>
        <span style={{ flex: 1, textAlign: 'center', fontSize: 20, lineHeight: '56px' }}>
          <span>{formatDuration(timer)}</span>
        </span>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <div className="stopwatch-btn" onClick={handleStop}>
            {'Stop'}
          </div>
        </div>
      </div>
    </div>
  );
}
