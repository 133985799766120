import { Transducer } from '../../utils/transducer';

export const SelectStyle = {
  singleValue: (base) => ({
    ...base,
    color: Transducer.getPreference('dark_mode') === 'true' ? '#ffffff' : '#2b2b2b',
  }),
  control: (base, state) => ({
    ...base,
    // FIXME These border colors don't look the way I want them to
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(213, 193, 150, 0.2)' : 'none',
    borderColor: state.isFocused ? 'rgb(196, 178, 132)' : 'none',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'rgb(196, 178, 132)' : 'transparent',
    color: state.isSelected ? '#2b2b2b' : 'inherit',
    ':active': {
      backgroundColor: state.isSelected ? 'rgb(196, 178, 132)' : 'transparent',
      color: state.isSelected ? '#2b2b2b' : 'inherit',
    },
  }),
  input: (base) => ({
    ...base,
    color: Transducer.getPreference('dark_mode') === 'true' ? '#ffffff' : '#2b2b2b',
  }),
};
